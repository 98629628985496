import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import {
  combinedResourceObjects,
  denormalisedEntities,
} from '../../../../util/data';
import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFeaturedListings.module.css';
import { getFeaturedListings } from '../../../../util/api';
import { ListingCard } from '../../../../components';
import config from '../../../../config';
import { createInstance, types as sdkTypes } from '../../../../util/sdkLoader';
import * as apiUtils from '../../../../util/api';
import {
  addMarketplaceEntities,
  getMarketplaceEntities,
} from '../../../../ducks/marketplaceData.duck';
import { sanitizeEntity } from '../../../../util/sanitize';
import { FormattedMessage } from '../../../../util/reactIntl';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block

const createImageVariantConfig = (name, width, aspectRatio) => {
  const height = aspectRatio * width;
  if (width > 3072 || height > 3072) {
    throw new Error(`Dimensions of custom image variant (${name}) are too high (w:${width}, h:${height}).
    Reduce them to max 3072px. https://www.sharetribe.com/api-reference/marketplace.html#custom-image-variants`);
  }

  return {
    [`imageVariant.${name}`]: `w:${width};h:${aspectRatio*width};fit:crop`
  };
};

const baseUrl = config.sdk.baseUrl ? { baseUrl: config.sdk.baseUrl } : {};
const assetCdnBaseUrl = config.sdk.assetCdnBaseUrl
  ? { assetCdnBaseUrl: config.sdk.assetCdnBaseUrl }
  : {};

const sdk = createInstance({
  transitVerbose: config.sdk.transitVerbose,
  clientId: config.sdk.clientId,
  secure: config.usingSSL,
  typeHandlers: apiUtils.typeHandlers,
  ...baseUrl,
  ...assetCdnBaseUrl,
});

const SectionFeaturedListings = props => {
  const {
    sectionId,
    className,
    defaultClasses,
    rootClassName,
    appearance,
    blocks,
    options,
    isInsideContainer
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

 // const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;
  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 30;
      const panelLargeWidth = 40;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 30;
      const panelLargeWidth = 40;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };
  const [state, setState ] = useState({listings: [], entities: []});

  const variantPrefix = 'listing-card';
  const aspectRatio = 1;

  const updatedEntities = (apiResponse) => {
    const { data, included = [] } = apiResponse;
    const objects = (Array.isArray(data) ? data : [data]).concat(included);

    const newEntities = objects.reduce((entities, curr) => {
      const { id, type } = curr;

      // Some entities (e.g. listing and user) might include extended data,
      // you should check if src/util/sanitize.js needs to be updated.
      const current = sanitizeEntity(curr);

      entities[type] = entities[type] || {};
      const entity = entities[type][id.uuid];
      entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

      return entities;
    }, state.entities);

    return newEntities;
  };
  useEffect(() => {
    sdk.listings
      .query({meta_featured:true,
        include: ['author', 'images'],
        'fields.listing': ['title', 'geolocation', 'price'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1}).then(resp => {

      setState({listings: getListingsById(updatedEntities(resp.data),resp.data.data), entities: updatedEntities(resp.data)});


    });
  },[])


  const getListingsById = (entities,listingIds) => {

    const resources = listingIds.map(l => ({
      id:l.id,
      type: 'listing',
    }));
    const throwIfNotFound = false;
    return denormalisedEntities(entities, resources, throwIfNotFound);
  };

  return (state.listings.length > 0 ?
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
      padding={false}
   >
      <h2 className={classNames(defaultClasses.title, {
        [css.noSidePaddings]: isInsideContainer,
      },css.centerTitle)}><FormattedMessage id={'FeaturedListings.title'} /></h2>
      <div
        className={classNames(defaultClasses.blockContainer, css.articleMain, {
          [css.noSidePaddings]: isInsideContainer,
        })}
      >
        <div className={css.listingCards}>
          {state.listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes(false)}

            />
          ))}

        </div>
      </div>


    </SectionContainer> : null
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeaturedListings.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  defaultClasses: null,
  isInsideContainer:false

};

SectionFeaturedListings.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  isInsideContainer:bool
};

export default SectionFeaturedListings;
