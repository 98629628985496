import React from 'react';
import {arrayOf, bool, func, node, object, shape, string} from 'prop-types';
import classNames from 'classnames';

import Field, {hasDataInFields} from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionArticle.module.css';
import {PrimaryButton} from '../../../../components';
import {NamedLink} from '../../../../components';

import {filters} from '../../../../config/marketplace-custom-config';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionArticle = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = {fieldComponents};

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  return (

    <>
      {sectionId === "hero-v2" &&
        <div className={css.categoryBar}>
          {filters.map((filter) => {
            if (['SelectSingleFilter', 'SelectSingleFilterNested', 'SelectMultipleFilter'].includes(filter.type)) {
              const searchString = filter?.config?.options?.map(option => {
                return (option.key)
              })
              let joinedSearchString = ''
              if (searchString) {
                joinedSearchString = searchString.join('%2C')
              }
              return (
                <NamedLink className={css.step} name="SearchPage" to={{search: `?pub_category=${joinedSearchString}`}}>
                  <h3>{filter.label}</h3>
                </NamedLink>
              )
            }
          })
          }
        </div>
      }
      <SectionContainer
        id={sectionId}
        className={className}
        rootClassName={rootClassName}
        appearance={appearance}
        options={fieldOptions}
      >
        {hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            <Field data={description} className={defaultClasses.description} options={fieldOptions} />
            {sectionId === "hero-v2" ?
              <div className={css.twoButtonDiv}>
                <a href="/s" className={css.ctaButton} >Buy Now</a>
                <Field data={callToAction} className={css.ctaButton} options={fieldOptions} />
              </div>
              : <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />}
          </header>
        ) : null}
        {hasBlocks ? (
          <div
            className={classNames(defaultClasses.blockContainer, css.articleMain, {
              [css.noSidePaddings]: isInsideContainer,
            })}
          >
            <BlockBuilder
              blocks={blocks}
              ctaButtonClass={defaultClasses.ctaButton}
              options={options}
            />
          </div>
        ) : null}
      </SectionContainer>
    </>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({component: node, pickValidProps: func}),
});

SectionArticle.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionArticle.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionArticle;
